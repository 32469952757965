.Logos-Container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;

    .Logos-Item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;

        p {
            margin: 0;
        }

        a {
            margin: 1rem;
            transition: background-color 0.15s ease-in-out;

            &:hover {
                background-color: rgb(238, 238, 238);
                border-radius: 3px;
            }

            img {
                width: 250px;
            } 
        }
        
    }
}

@media screen and (max-width: 1024px) {
    .Logos-Container {
        justify-content: center;
        align-items: center;
    }
}
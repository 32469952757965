@import "../Styles/colors.scss";

.Footer__Links, .Footer__Sozial{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // box-sizing: border-box;

    a {
        margin: 20px;
        color: $color-Background;
    }
}

@media screen and (max-width: 1024px) {
    .Footer__Links, .Footer__Sozial {
        flex: 1 30%;
    }
}

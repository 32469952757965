// imports //
@import "../Styles/colors.scss";
@import url('https://fonts.googleapis.com/css?family=Raleway|Roboto+Slab:200,400,600&display=swap');

// Basic Declarations //
html, body {
    margin: 0;
    padding: 0;
    color: $color-TextBasic;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
}

a {
    text-decoration: none;
}

p {
    line-height: 1.6;
    text-align: distribute;
    margin-block-end: 1rem;
}

h1 {
    font-weight: 200;
    font-size: 2.5rem;
    padding: 0;
    margin: 1rem 0;
}

h4 {
    margin: 0.5rem 0;
    padding: 0;
}

h5 {
    margin: 0;
    padding: 0;
}

// CSS GRID //
.Layout {
    width: 100%;
    background-color: $color-Background;
    display: grid;
    grid-template-columns:  repeat(5, auto);
    grid-template-rows:     repeat(5, auto);
    grid-template-areas:

        "Nav Nav Nav Nav Nav"
    "Burger Burger Burger Burger Burger"

        "Page Page Page Page Page"
        "Page Page Page Page Page"

    "Footer Footer Footer Footer Footer"
           "Bar Bar Bar Bar Bar"
}

.Nav {
    grid-area: Nav;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.Burger__Box {
    grid-area: Burger;
}

.Page {
    opacity: 0;
    grid-area: Page;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    text-align: left;

    .Page__Content {
        width: 100%;
        max-width: 800px;
        padding: 0 10px;
        box-sizing: border-box;
    }
}

.Page-IsVisible {
    transition: opacity 0.35s ease-in;
    opacity: 1;
}

.Footer {
    grid-area: Footer;
    text-align: center;
    width: 100%;
    min-height: 40vh;
    margin-block-start: 2rem;
    background-color: $color-Footer;
}

.BottomBar {
    grid-area: Bar;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(70, 70, 70);
    color: $color-Background;
    height: 30px;
}

// Banners //

.Page__Banner {
    width: 100%;
    height: 250px;
    min-height: 250px;
    background-size: cover;
}

.Banner__Home {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: background 0.35s ease-in;

    div {
        min-width: 300px;
        flex: 1;
        background-size: cover;
    }

    .Banner__Home-1 {
        background-image: url("/images/turbine.jpeg");
        background-position: 50% 45%;
        filter: saturate(1.5);
    }

    .Banner__Home-2 {
        background-image: url("/images/lab.jpg");
        background-position: 50% 45%;
    }

    .Banner__Home-3{
        background-image: url("/images/furnace.jpg");
        background-position: 50% 45%; 
    }

}

.Banner__Consulting {
    background-image: url("/images/consulting.jpg");
    background-position: 50% 55%;
    filter: contrast(0.85)
}

.Banner__Participation {
    background-image: url("/images/graph.jpg");
    background-position: 50% 65%;
}

.Banner__Partners {
    background-image: url("/images/circuit.jpg");
    background-position: 50% 50%;
}

.Banner__Contact {
    background-image: url("/images/contact.jpg");
    background-position: 50% 75%;
    filter: saturate(0.8)
    
}

.Banner__Legal {
    background-image: url("/images/file.jpg");
    background-position: 50% 50%;
}

// MISC //

.List-Container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.List-Item {
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgray;
    text-align: center;
    padding: 0.5rem;
    max-width: 500px;
    margin: 0.5rem 0;
}

// MEDIAQUERIES

@media screen and (min-width: 768px) and (max-width: 1024px) {
    
    html, body {
        font-size: 16px;
    }

    p {
        font-size: 1.2rem;
    }

    .Banner__Home {
        .Banner__Home-2 {
            display: none;
        }
    }

    .List-Container {
        justify-content: center;
        align-items: center;
        
        .List-Item {
            width: 600px;
        }
    }


}

@media screen and (max-width: 768px) {

    html, body {
        font-size: 14px;
    }

    p {
        font-size: 1.2rem;
        line-height: 1.4;
    }

    .Banner__Home {
        .Banner__Home-2 {
            display: none;
        }
        .Banner__Home-3 {
            display: none;
        }
    }

    .List-Container {
        
        .List-Item {
            min-width: 300px;
        }
    }

}
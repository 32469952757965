.Page__Legal {

    ul {
        padding: 0;
    }

    li {
        list-style-type: none;
    }

    p {
        margin: 0.5rem 0;
    }

    h1 {
        margin-block-end: 2rem;
    }
}
.Page__Contact {
  p {
    min-width: 200px;
    padding: 0.5rem;
    margin: 0;
  }

  .Page__Contact__Info {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
}

.Page__Contact-Adress {

    ul {
        margin: 0;
        padding: 0;
    }

    li {
      list-style-type: none;  
    }   
}


h1 {
  margin-bottom: 0;
}

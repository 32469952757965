@import "../Styles/colors.scss";

.Nav {
    margin-block-start: 2vh;
    margin-block-end: 1vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;

    .Nav__Logo {
        flex: 1;
        img {
            width: 150px;
        }
    }

    .Nav__Links {
        flex: 2;

        a {
            margin-inline-end: 50px;
            color: $color-Nav-Link;
            font-size: 1.1rem;
            font-weight: 200;

            &:visited {
                color: $color-Nav-Link;
            }

            &:hover {
                color: $color-Nav-Hover;
                font-weight: 400;
            }
        }
    }

    .Nav__BurgerMenu-Icon {
        display: none;
    }

    .Nav__Lang {
        display: flex;
        flex-direction: column;
        
        .Nav__Lang-Icon {
            padding: 0 1rem;
            width: 30px;
            cursor: pointer;
            user-select: none;
        }
    }
}

.Burger__Box {
    width: 100%;
    height: 0px;
    transition: height 0.20s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;

    a {
        color: $color-Nav-Link;
        font-size: 1.1rem;
        font-weight: 200;

        &:visited {
            color: $color-Nav-Link;
        }
    }
}

.Burger__Box--active {
    height: 200px;
    transition: height 0.20s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

@media screen and (max-width: 1024px) {
    .Nav {

        .Nav__Links {
            display: none;
        }

        .Nav__BurgerMenu-Icon{
            display: block;
        }  
    }
    
}
$color-Primary: rgb(41, 72, 131);
$color-Secondary: rgb(127, 127, 127);

$color-TextBasic: rgb(58, 58, 58);

$color-Background: rgb(250, 250, 250);
$color-Footer: grey;

$color-Nav-Link: $color-TextBasic;
$color-Nav-Hover: $color-Primary;
$color-Nav-Selected: $color-Background;

$color-Input-Focus: $color-Primary;

$color-Btn: $color-Secondary;
$color-Btn-Bright: $color-Background;
$color-Btn-Hover: $color-Primary;





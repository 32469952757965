@import "../Styles/colors.scss";

@mixin forminputs {
    border: 2px solid rgb(197, 196, 196);
    border-radius: 5px;
    outline: none;

    &:focus {
        border: 2px solid $color-Input-Focus;
        box-shadow: 0 0 5px $color-Input-Focus;
    }
}

.ContactForm__Notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20px;
    padding-bottom: 3px;
}

.ContactForm__Form {
    width: 100%;
    max-width: 800px ;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}


.ContactForm__Form__Inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    input {
        @include forminputs;
        box-sizing: borderbox;
        width: 100%;
        height: 40px;
        padding: 0 10px;
        margin: 5px 0;
    }
}

.ContactForm__Form__Message {
    @include forminputs;
    min-height: 200px;
    width: 100%;
    margin: 20px 0;
    padding: 10px;
    box-sizing: border-box;
}

.ContactForm__Form__SubmitBtn {
    width: 200px;
    height: 40px;
    font-weight: 600;
    @include forminputs;
    border-style: none;
    cursor: pointer;
    background-color: $color-Btn;
    color: $color-Btn-Bright;
}

.ContactForm__Form__SubmitBtn:hover {
    background-color: $color-Btn-Hover;  
    color: $color-Nav-Selected
}
